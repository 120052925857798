import { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../utils/ResultViewer/Table";
const WatchdogList: FC = () => {


  const { t } = useTranslation();

  return (
    <>
      <Table
        title={"Son Günlük Olaylar"}
        dataUrl="/watchdog/list"
        editCallback={(id: any, refresh: Function) => {}}
        deleteCallback={(id: any, refresh: Function) => () => {}}
        actionItems={[
            {
                text: t("Add Watchdog"),
                class: "btn btn-sm btn-primary",
                icon: "fa fa-plus",
                onClick: function (event: SyntheticEvent, refresh: Function) {},
            },
        ]}
      />
    </>
  );
};

export { WatchdogList };
