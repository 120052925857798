import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  FormLabel,
  Button,
  FormCheck,
} from "react-bootstrap";

import {
  PropertyFormKeys,
  PropertyPostData,
  SelectListItem,
} from "../core/_models";
import {
  getPropertyFromId,
  getCategoriesOptionsList,
  getFeaturesOptionsList,
  getUsersOptionsList,
  getPropertyFormKeys,
  postPropertyForm,
  getRegionsOptionsList,
  getPoolTypes,
} from "../core/_requests";
import { useNavigate, useParams } from "react-router-dom";
import Textarea from "../../../utils/Form/Widgets/Textarea";

function PropertyEdit() {
  const { property_id } = useParams();
  const navigate = useNavigate();
  const [regionOptionsList, setRegionOptionsList] = useState<SelectListItem[]>(
    []
  );
  const [categoriesOptionsList, setCategoriesOptionsList] = useState<
    SelectListItem[]
  >([]);
  const [featuresOptionsList, setFeaturesOptionsList] = useState<
    SelectListItem[]
  >([]);
  const [usersOptionsList, setUsersOptionsList] = useState<SelectListItem[]>(
    []
  );
  const [poolsOptionsList, setPoolsOptionsList] = useState<SelectListItem[]>(
    []
  );
  const [propertyFormKeys, setPropertyFormKeys] = useState<PropertyFormKeys>();
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [selectedOwner, setSelectedOwner] = useState([0]);
  const [selectedType, setSelectedType] = useState("");
  const [location, setLocation] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [district, setDistrict] = useState("");
  const [selectedCapacity, setSelectedCapacity] = useState("");
  const [selectedBedroomCount, setSelectedBedroomCount] = useState(0);
  const [selectedBathroomCount, setSelectedBathroomCount] = useState(0);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");
  const [description, setDescription] = useState("");
  const [weight, setWeight] = useState(0);
  const [superTag, setSuperTag] = useState("");
  const [deposit, setDeposit] = useState("");
  const [freeCleaningServiceDate, setFreeCleaningServiceDate] = useState(0);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [urlAlias, setUrlAlias] = useState("");
  /*  const [selectedPools, setSelectedPools] = useState<any[]>([]);
  const [poolData, setPoolData] = useState<any[]>([
    { pool: "", width: 0, length: 0, depth: 0 },
  ]);
*/
  const fetchPropertyData = async () => {
    if (property_id === "add") {
      return null;
    } else {
      const property = await getPropertyFromId(property_id);
      console.log("property:", property);
      if (property) {
        setName(property.name);
        setSelectedOwner(property.users);
        setSelectedType(property.type);
        setLocation(property.location);
        setSelectedRegion(property.region);
        setDistrict(property.district);
        setSelectedCapacity(property.capacity);
        setSelectedBedroomCount(property.bedroom_count);
        setSelectedBathroomCount(property.bathroom_count);
        setSelectedFeatures(property.features);
        setSelectedCategories(property.categories);
        setSelectedStatus(property.status);
        setDescriptionTitle(property.description_title);
        setDescription(property.description);
        setWeight(property.weight);
        setSuperTag(property.super_tag);
        setDeposit(property.deposit);
        setFreeCleaningServiceDate(property.free_cleaning_service_min_date);
        setMetaTitle(property.meta_title);
        setMetaDescription(property.meta_description);
        setUrlAlias(property.url_alias);
        // setSelectedPools(property.pools);
      }
    }
  };
  console.log("selected categories", selectedCategories);
  const fetchRegionOptionsList = async () => {
    const regions = await getRegionsOptionsList();
    setRegionOptionsList(regions);
  };

  const fetchCategoriesOptionsList = async () => {
    const categories = await getCategoriesOptionsList();
    setCategoriesOptionsList(categories);
  };

  const fetchFeaturesOptionsList = async () => {
    const features = await getFeaturesOptionsList();
    setFeaturesOptionsList(features);
  };

  const fetchUsersOptionsList = async () => {
    const users = await getUsersOptionsList();
    setUsersOptionsList(users);
  };

  const fetchPropertyFormKeys = async () => {
    const keys = await getPropertyFormKeys();
    setPropertyFormKeys(keys);
  };
  /*
  const fetchPoolTypes = async () => {
    const poolTypes = await getPoolTypes();
    setPoolsOptionsList(poolTypes);
  };
*/
  useEffect(() => {
    fetchPropertyData();
    fetchRegionOptionsList();
    fetchCategoriesOptionsList();
    fetchFeaturesOptionsList();
    fetchUsersOptionsList();
    fetchPropertyFormKeys();
    //fetchPoolTypes();
  }, []);

  const isMobile = window.innerWidth < 990;

  const propertyTypes = [
    { value: "villa", label: "Villa" },
    { value: "hotel", label: "Otel" },
    { value: "pansion", label: "Pansiyon" },
  ];

  const propertyCapacities = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "0", label: "10+" },
  ];

  const countOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];

  const propertyStatus = [
    { value: "1", label: "Aktif" },
    { value: "0", label: "Pasif" },
  ];

  const handleNameChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setName(event.target.value);
  };

  const handleOwnerChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedOwner([Number(event.target.value)]);
  };

  const handleTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedType(event.target.value);
  };
  const handleSelectAll = () => {
    const allChecked =
      selectedCategories.length === categoriesOptionsList.length;
    setSelectedCategories(
      allChecked ? [] : categoriesOptionsList?.map((cat) => cat.value)
    );
  };

  const handleSelectAllFeatures = () => {
    const allChecked = selectedFeatures.length === featuresOptionsList.length;
    setSelectedFeatures(
      allChecked ? [] : featuresOptionsList?.map((cat) => cat.value)
    );
  };

  const handleLocationChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLocation(event.target.value);
  };
  const handleDistrictChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDistrict(event.target.value);
  };
  const handleRegionChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedRegion(event.target.value);
  };

  const handleCapacityChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedCapacity(event.target.value);
  };

  const handleBedroomCountChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedBedroomCount(Number(event.target.value));
  };

  const handleBathroomCountChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedBathroomCount(Number(event.target.value));
  };

  const handleDescriptionChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDescription(event.target.value);
  };

  const handleFeatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedFeatures([...selectedFeatures, value]);
    } else {
      setSelectedFeatures(
        selectedFeatures.filter((feature) => feature !== value)
      );
    }
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (checked) {
      setSelectedCategories([...selectedCategories, value]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== value)
      );
    }
  };

  const handleStatusChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedStatus(event.target.value);
  };

  const handleDescriptionTitleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDescriptionTitle(event.target.value);
  };

  const handleWeightChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setWeight(Number(event.target.value));
  };

  const handleSuperTagChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSuperTag(event.target.value);
  };

  const handleDepositChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDeposit(event.target.value);
  };

  const handleFreeCleaningServiceDateChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setFreeCleaningServiceDate(Number(event.target.value));
  };

  const handleMetaTitleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setMetaTitle(event.target.value);
  };

  const handleMetaDescriptionChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setMetaDescription(event.target.value);
  };

  const handleUrlAliasChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setUrlAlias(event.target.value);
  };

  /*
  const [pools, setPools] = useState<
    { type: any; width: number; length: number; depth: number }[]
  >([]);

  const handleAddPool = (poolType: any) => {
    const newPool = {
      type: poolType,
      width: 0,
      length: 0,
      depth: 0,
    };
    setPools([...pools, newPool]);
  };

  const handleRemovePool = (index: any) => {
    const updatedPools = [...pools];
    updatedPools.splice(index, 1);
    setPools(updatedPools);
  };

  const handleUpdatePoolSize = (
    index: number,
    key: keyof { type: any; width: number; length: number; depth: number },
    value: any
  ) => {
    const updatedPools = [...pools];
    updatedPools[index][key] = value;
    setPools(updatedPools);
  };
*/
  const handleSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const data: PropertyPostData = {
        properties: {
          name: name,
          super_tag: superTag,
          type: selectedType,
          description_title: descriptionTitle,
          description: description,
          status: selectedStatus,
          region: selectedRegion,
          district: district,
          location: location,
          capacity: selectedCapacity,
          bedroom_count: selectedBedroomCount,
          bathroom_count: selectedBathroomCount,
          weight: weight,
          deposit: deposit,
          free_cleaning_service_min_date: freeCleaningServiceDate,
          meta_title: metaTitle,
          meta_description: metaDescription,
          url_alias: urlAlias,
          users: selectedOwner,
          categories: selectedCategories as [],
          features: selectedFeatures as [],
          //pools: selectedPools as [],
        },
        form_id: propertyFormKeys ? propertyFormKeys.form_id || "" : "",
        form_build_id: propertyFormKeys
          ? propertyFormKeys.form_build_id || ""
          : "",
        form_token: propertyFormKeys ? propertyFormKeys.form_token || "" : "",
      };
      try {
        const response = await postPropertyForm(property_id, data);
        setTimeout(() => {
          if (response.data.data.success) {
            window.location.reload();
          }
        }, 2000);
      } catch (error) {
        console.error("Form submission error:", error);
      }
    }
    setValidated(true);
  };

  return (
    <>
      <Form
        method="POST"
        action="deneme"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Row>
          <Col lg={12} style={{ marginBottom: "5px" }}>
            <div className="text-center mb-3">
              <Button
                className="btn w-sm btn-secondary waves-effect me-1"
                style={{ float: "left" }}
                onClick={() => {
                  navigate("/properties/list");
                }}
              >
                <i
                  className="fas fa-chevron-left"
                  style={{ marginRight: "5px" }}
                ></i>
                Geri
              </Button>
              {property_id !== "add" && (
                <>
                  <Button
                    className="btn w-sm btn-primary waves-effect waves-light"
                    style={{ marginLeft: "10px", float: "right" }}
                    onClick={() => {
                      navigate("/property_images/order/" + property_id);
                    }}
                  >
                    <i className="fa fa-plus"></i> Mülk Resimleri
                  </Button>
                  <Button
                    className="btn w-sm btn-info waves-effect waves-light"
                    style={{ float: "right" }}
                    onClick={() => {
                      navigate("/property_pools/list/" + property_id);
                    }}
                  >
                    <i className="fas fa-swimming-pool"></i> Havuz Bilgileri
                  </Button>
                </>
              )}
              :<></>
            </div>
          </Col>
          <Col lg={6}>
            <Row
              style={{
                height: "100%",
                ...(isMobile ? {} : { marginRight: "10px" }),
              }}
            >
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                    Genel
                  </h5>
                  <FormGroup className="mb-3" controlId="nameValidation">
                    <FormLabel>Mülk Adı</FormLabel>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Mülk Adı"
                      required={true}
                      onChange={handleNameChange}
                      value={name}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk adını girin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="superTagValidation">
                    <FormLabel>Süper Etiket</FormLabel>
                    <Form.Control
                      type="text"
                      name="super_tag"
                      placeholder="Mülk Süper Etiket"
                      required={true}
                      onChange={handleSuperTagChange}
                      value={superTag}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen süper etiketi girin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="urlAliasValidation">
                    <FormLabel>Mülk URL</FormLabel>
                    <Form.Control
                      type="text"
                      name="url_alias"
                      placeholder="Mülk URL"
                      required={true}
                      onChange={handleUrlAliasChange}
                      value={urlAlias}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen url girin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="metaTitleValidation">
                    <FormLabel>Meta Başlığı</FormLabel>
                    <Form.Control
                      type="text"
                      name="meta_title"
                      placeholder="Meta Başlığı"
                      required={true}
                      onChange={handleMetaTitleChange}
                      value={metaTitle}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen meta başlığı girin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup
                    className="mb-3"
                    controlId="metaDescriptionValidation"
                  >
                    <FormLabel>Meta Açıklaması</FormLabel>
                    <Form.Control
                      type="text"
                      name="meta_description"
                      placeholder="Meta Açıklaması"
                      required={true}
                      onChange={handleMetaDescriptionChange}
                      value={metaDescription}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen meta açıklaması girin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="ownerValidation">
                    <FormLabel>Mülk Sahibi</FormLabel>
                    <Form.Control
                      as="select"
                      name="owner"
                      placeholder="Mülk Sahibi Seçin"
                      className="react-select react-select-container"
                      prefix="react-select"
                      multiple={false}
                      required={true}
                      onChange={handleOwnerChange}
                      value={selectedOwner[0]}
                    >
                      <option value="">Mülk Sahibi Seçin</option>
                      {usersOptionsList?.map((propOwner) => (
                        <option key={propOwner.value} value={propOwner.value}>
                          {propOwner.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Lütfen bir sahip seçin.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <FormLabel>Mülk Türü</FormLabel>
                    <Form.Control
                      as="select"
                      name="type"
                      placeholder="Mülk Türünü Seçin"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleTypeChange}
                      value={selectedType}
                    >
                      <option value="">Mülk Türünü Seçin</option>
                      {propertyTypes?.map((propType) => (
                        <option key={propType.value} value={propType.value}>
                          {propType.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk türünü seçin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <FormLabel>Mülk Bölgesi</FormLabel>
                    <Form.Control
                      as="select"
                      name="region"
                      placeholder="Mülk Bölgesini Seçin"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleRegionChange}
                      value={selectedRegion}
                    >
                      <option value="">Mülk Bölgesini Seçin</option>
                      {regionOptionsList?.map((propRegion) => (
                        <option key={propRegion.value} value={propRegion.value}>
                          {propRegion.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk bölgesini seçin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="districtValidation">
                    <FormLabel>İlçe</FormLabel>
                    <Form.Control
                      type="text"
                      name="district"
                      placeholder="Mülk İlçesi"
                      required={true}
                      onChange={handleDistrictChange}
                      value={district}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk ilçesini girin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="locationValidation">
                    <FormLabel>Mülk Konumu</FormLabel>
                    <Form.Control
                      type="text"
                      name="location"
                      placeholder="Mülk Konumu"
                      required={true}
                      onChange={handleLocationChange}
                      value={location}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk konumunu girin.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <FormLabel>Mülk Kapasitesi</FormLabel>
                    <Form.Control
                      as="select"
                      name="capacity"
                      placeholder="Mülk Kapasitesini Seçin"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleCapacityChange}
                      value={selectedCapacity}
                    >
                      <option value="">Select Property Capacity</option>
                      {propertyCapacities.map((propCapacity) => (
                        <option
                          key={propCapacity.value}
                          value={propCapacity.value}
                        >
                          {propCapacity.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select property capacity.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <FormLabel>Yatak Odası Sayısı</FormLabel>
                    <Form.Control
                      as="select"
                      name="bedroom_count"
                      placeholder="Select Property Bedroom Count"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleBedroomCountChange}
                      value={selectedBedroomCount}
                    >
                      <option value="">Select Property Bedroom Count</option>
                      {propertyCapacities.map((propCapacity) => (
                        <option
                          key={propCapacity.value}
                          value={propCapacity.value}
                        >
                          {propCapacity.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk yatak odası sayısını seçin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <FormLabel>Banyo Sayısı</FormLabel>
                    <Form.Control
                      as="select"
                      name="bahtroom_count"
                      placeholder="Select Property Bathroom Count"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleBathroomCountChange}
                      value={selectedBathroomCount}
                    >
                      <option value="">Banyo Sayısı Seçiniz</option>
                      {propertyCapacities?.map((propCapacity) => (
                        <option
                          key={propCapacity.value}
                          value={propCapacity.value}
                        >
                          {propCapacity.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk banyo sayısını seçin.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <FormLabel>Free Temizlik Ücreti Min Gün Sayısı</FormLabel>
                    <Form.Control
                      as="select"
                      name="free_cleaning_service_min_date"
                      placeholder="Select Property Free Cleaning Service Min Date"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleFreeCleaningServiceDateChange}
                      value={freeCleaningServiceDate}
                    >
                      <option value="">
                        Free Temizlik Ücreti Min Gün Sayısı
                      </option>
                      {countOptions?.map((count) => (
                        <option key={count.value} value={count.value}>
                          {count.label}
                        </option>
                      ))}
                    </Form.Control>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="depositValidation">
                    <FormLabel>Depozito</FormLabel>
                    <Form.Control
                      type="text"
                      name="deposit"
                      placeholder="Property Deposit"
                      required={true}
                      onChange={handleDepositChange}
                      value={deposit}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk depozitosunu girin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3" controlId="weightValidation">
                    <FormLabel>Mülk Sıralaması</FormLabel>
                    <Form.Control
                      type="text"
                      name="weight"
                      placeholder="Property Weight"
                      required={true}
                      onChange={handleWeightChange}
                      value={weight}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfen mülk sıralamasını girin.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <FormLabel className="mb-2">Property Status</FormLabel>
                    <div className="">
                      {propertyStatus?.map((propStatus) => (
                        <div key={propStatus.value}>
                          <Form.Check
                            type="radio"
                            id={propStatus.value}
                            name="status"
                            value={propStatus.value}
                            className="me-1"
                            label={propStatus.label}
                            required={true}
                            feedback="Please select property status."
                            feedbackType="invalid"
                            checked={"" + selectedStatus === propStatus.value}
                            onChange={handleStatusChange}
                          />
                        </div>
                      ))}
                    </div>
                  </FormGroup>
                </Card.Body>
              </Card>
            </Row>
          </Col>
          <Col lg={6}>
            <Row>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2 d-flex justify-content-between align-items-center">
                    Mülk Özellikleri
                    <Button
                      variant={
                        selectedFeatures.length === featuresOptionsList.length
                          ? "danger"
                          : "success"
                      }
                      size="sm"
                      onClick={handleSelectAllFeatures}
                      style={{ marginLeft: "auto" }}
                    >
                      {selectedFeatures.length === featuresOptionsList.length
                        ? "Temizle"
                        : "Hepsini Seç"}
                    </Button>
                  </h5>
                  <div
                    className="features"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxHeight: "300px",
                    }}
                  >
                    {featuresOptionsList?.map((propFeature) => (
                      <div
                        key={propFeature.value}
                        style={{
                          flex: "0 0 20%",
                          boxSizing: "border-box",
                          padding: "5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <FormCheck
                          multiple={true}
                          type="checkbox"
                          id={propFeature.value}
                          name="features"
                          className="me-1"
                          value={propFeature.value}
                          label={propFeature.label}
                          onChange={handleFeatureChange}
                          checked={selectedFeatures.includes(propFeature.value)}
                          style={{ width: "100%" }}
                        />
                      </div>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Row>

            <Row>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2 d-flex justify-content-between align-items-center">
                    Mülk Kategorileri
                    <Button
                      variant={
                        selectedCategories.length ===
                        categoriesOptionsList.length
                          ? "danger"
                          : "success"
                      }
                      size="sm"
                      onClick={handleSelectAll}
                      style={{ marginLeft: "auto" }}
                    >
                      {selectedCategories.length ===
                      categoriesOptionsList.length
                        ? "Temizle"
                        : "Hepsini Seç"}
                    </Button>
                  </h5>

                  <div
                    className="category"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxHeight: "300px",
                    }}
                  >
                    {categoriesOptionsList?.map((propCategory) => (
                      <div
                        key={propCategory.value}
                        style={{
                          flex: "0 0 20%",
                          boxSizing: "border-box",
                          padding: "5px",
                        }}
                      >
                        <FormCheck
                          multiple={true}
                          type="checkbox"
                          id={propCategory.value}
                          name="categories"
                          className="me-1"
                          value={propCategory.value}
                          label={propCategory.label}
                          onChange={handleCategoryChange}
                          checked={selectedCategories.includes(
                            propCategory.value
                          )}
                        />
                      </div>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Row>
            <Row>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                    Mülk Açıklaması
                  </h5>

                  <FormGroup
                    className="mb-3"
                    controlId="descriptionTitleValidation"
                  >
                    <FormLabel>Mülk Açıklama Başlığı</FormLabel>
                    <Form.Control
                      type="text"
                      name="description_title"
                      placeholder="Property Description Title"
                      required={true}
                      onChange={handleDescriptionTitleChange}
                      value={descriptionTitle}
                    />
                    <Form.Control.Feedback type="invalid">
                      Lütfe mülk açıklama başlığını girin.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <Textarea
                      title="Mülk Açıklaması"
                      name="description"
                      value={description}
                      onChange={handleDescriptionChange}
                      required={true}
                    />
                  </FormGroup>
                </Card.Body>
              </Card>
            </Row>
            {/*
            <Row>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                    Havuz Bilgileri
                  </h5>

                  <Form.Group
                    controlId="poolType"
                    className="d-flex align-items-center"
                  >
                    <div className="flex-grow-1">
                      <Form.Control
                        as="select"
                        onChange={(e) => handleAddPool(e.target.value)}
                      >
                        {poolsOptionsList?.map((poolType) => (
                          <option key={poolType.value} value={poolType.label}>
                            {poolType.label}
                          </option>
                        ))}
                       
                      </Form.Control>
                    </div>
                  </Form.Group>
                  <div className="mt-3">
                    <h5>Havuz Listesi</h5>
                    <div>
                      {pools.map((pool, index) => (
                        <Row key={index} className="mb-2">
                          <Col>{pool.type}</Col>
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="En"
                              value={pool.width}
                              onChange={(e) =>
                                handleUpdatePoolSize(
                                  index,
                                  "width",
                                  e.target.value
                                )
                              }
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              placeholder="Boy"
                              value={pool.length}
                              onChange={(e) =>
                                handleUpdatePoolSize(
                                  index,
                                  "length",
                                  e.target.value
                                )
                              }
                            />
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              title="Derinlik"
                              placeholder="Derinlik"
                              value={pool.depth}
                              onChange={(e) =>
                                handleUpdatePoolSize(
                                  index,
                                  "depth",
                                  e.target.value
                                )
                              }
                            />
                          </Col>
                          <Col>
                            <Button
                              variant="danger"
                              onClick={() => handleRemovePool(index)}
                              className="ml-2"
                            >
                              Sil
                            </Button>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Row>
            */}
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-center mb-3">
              <Button
                className="btn w-sm btn-success waves-effect waves-light me-1"
                style={{ width: "150px" }}
                type="submit"
              >
                Save
              </Button>
              <Button
                style={{ width: "150px" }}
                className="btn w-sm btn-danger waves-effect waves-light me-1"
              >
                Delete
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default PropertyEdit;
