import Table from "../../../../utils/ResultViewer/Table";

class RelationsTable extends Table {
  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (key == "reference_url") {
      return (
        <td key={colIndex}>
          <a href={row[key]} target="_blank" rel="noreferrer">
            <i className="fas fa-external-link-alt"></i>
          </a>
        </td>
      );
    } else {
      return super.renderRow(row, key, colIndex);
    }
  }
}

export default RelationsTable;
