import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { SearchWidget } from "./ResultViewer";
import Field from "../Form/Widgets/Field";
import { DateRangeInput } from "../Form/Widgets/DateRangeInput";
import Select from "../Form/Widgets/Select";
import AutocompleteSelect from "../Form/Widgets/AutocompleteSelect";

const Filters = (props: {
  search: SearchWidget[];
  setFilterParams: Function;
  applyFilter: Function;
  clearFilters: Function;
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [filterValues, setFilterValues] = useState<any>({});

  const handleApplyFilter = () => {
    props.applyFilter();
    setShowFilters(false);
  };

  const handleResetFilters = () => {
    props.clearFilters();
    setFilterValues({});
  };

  return (
    <>
      <div className="row g-3 align-items-center">
        <div className="col-auto">
          <Button
            variant="primary"
            onClick={() => setShowFilters(!showFilters)}
            className="me-2 btn btn-sm btn-primary"
          >
            <span className="fas fa-filter me-2"></span>
            Filtre
          </Button>
        </div>
      </div>

      {showFilters && (
        <div className="card card-body mt-3" style={{ right: "13%" }}>
          <h5 className="card-title">Filtreler</h5>
          {props.search.map((searchWidget) => {
            switch (searchWidget.type) {
              case "select":
                if (
                  searchWidget.attributes &&
                  (searchWidget.attributes as any)["data-autocomplete-token"]
                ) {
                  return (
                    <div className="px-7" key={searchWidget.name}>
                      <AutocompleteSelect
                        title={searchWidget.label}
                        name={searchWidget.name}
                        autoCompleteToken={
                          (searchWidget.attributes as any)[
                            "data-autocomplete-token"
                          ]
                        }
                        multiple={true}
                        defaultValue={filterValues[searchWidget.name] ?? 0}
                        onChange={(event: any) => {
                          props.setFilterParams(
                            searchWidget.name,
                            event.target.value
                          );
                          setFilterValues((prevFilterValues: any) => ({
                            ...prevFilterValues,
                            [searchWidget.name]: event.target.value,
                          }));
                        }}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className="px-7" key={searchWidget.name}>
                      <Select
                        title={searchWidget.label}
                        name={searchWidget.name}
                        options={
                          searchWidget.options
                            ? Object.keys(searchWidget.options).map((key) => {
                                return {
                                  value: key,
                                  label: (searchWidget.options as any)[key],
                                };
                              })
                            : []
                        }
                        defaultValue={
                          filterValues[searchWidget.name] ?? searchWidget.value
                        }
                        onChange={(event: any) => {
                          props.setFilterParams(
                            searchWidget.name,
                            event.target.value
                          );
                          setFilterValues((prevFilterValues: any) => ({
                            ...prevFilterValues,
                            [searchWidget.name]: event.target.value,
                          }));
                        }}
                        nullElement={searchWidget.nullElement}
                      />
                    </div>
                  );
                }
              case "daterange":
                return (
                  <div className="px-7" key={searchWidget.name}>
                    <DateRangeInput
                      title={searchWidget.label}
                      name={searchWidget.name}
                      seperator="&"
                      onChange={(event: any) => {
                        props.setFilterParams(
                          searchWidget.name,
                          event.target.value
                        );
                        setFilterValues((prevFilterValues: any) => ({
                          ...prevFilterValues,
                          [searchWidget.name]: event.target.value,
                        }));
                      }}
                    />
                  </div>
                );
              case "text":
              default:
                return (
                  <div className="px-7" key={searchWidget.name}>
                    <Field
                      title={searchWidget.label}
                      name={searchWidget.name}
                      autoComplete="off"
                      onChange={(event) => {
                        props.setFilterParams(
                          searchWidget.name,
                          event.target.value
                        );
                        setFilterValues((prevFilterValues: any) => ({
                          ...prevFilterValues,
                          [searchWidget.name]: event.target.value,
                        }));
                      }}
                    />
                  </div>
                );
            }
          })}
          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-light me-2"
              onClick={handleResetFilters}
            >
              Reset
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleApplyFilter}
            >
              Search
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Filters;
