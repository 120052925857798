import React from "react";

const Legend = () => {
  const legendItems = [
    { color: "#80ff80", label: "Müsait" },
    { color: "#FFA500", label: "Opsiyonlu" },
    { color: "#FFFF00", label: "Opsiyonlu-Vilbox" },
    { color: "#d3a2dc", label: "Müsait Değil-Vilbox" },
    { color: "#FF6666", label: "Müsait Değil-Other" },
    { color: "#D7D6D2", label: "Pasif" },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "10px",
      }}
    >
      {legendItems.map((item, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", marginRight: "15px" }}
        >
          <span
            style={{
              borderRadius: "50%",
              border: "1px solid #000",
              display: "inline-block",
              width: "20px",
              height: "20px",
              backgroundColor: item.color,
              marginRight: "5px",
            }}
          ></span>
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default Legend;
