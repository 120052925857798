import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import PageTitle from "../../utils/PageTitle";
import { PropertiesList } from "./components/PropertiesList";
import PropertyEdit from "./components/PropertyEdit";

const breadCrumbItems = [
  {
    label: "Mülkler",
    path: "/properties/list",
    active: false,
  },
];

const PropertiesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle
                breadCrumbItems={breadCrumbItems}
                title={"Tüm Mülkler"}
              />
              <PropertiesList />
            </>
          }
        />
        <Route
          path="edit/:property_id"
          element={
            <>
              <PageTitle
                breadCrumbItems={[
                  { label: "Tüm Mülkler", path: "/properties" },
                  {
                    label: "Mülk Ekle / Düzenle",
                    path: "/properties/edit",
                    active: true,
                  },
                ]}
                title={"Mülk Ekle / Düzenle"}
              />
              <PropertyEdit />
            </>
          }
        />
        <Route index element={<Navigate to="/properties/list" />} />
      </Route>
    </Routes>
  );
};

export default PropertiesPage;
