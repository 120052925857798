import { ReactNode } from "react";
import { FormGroup, Modal } from "react-bootstrap";
import FormHandler from "../../../utils/Form/FormHandler";
import Field from "../../../utils/Form/Widgets/Field";
import Textarea from "../../../utils/Form/Widgets/Textarea";
import AutocompleteSelect from "../../../utils/Form/Widgets/AutocompleteSelect";
import Select from "../../../utils/Form/Widgets/Select";
import MultipleFileInputWidget from "../../../utils/Form/Widgets/MultipleFileInputWidget";
import Check from "../../../utils/Form/Widgets/Check";

type Props = {
  onHide: () => void;
  show: boolean;
  itemIdForUpdate?: string;
  refreshFunction?: Function;
};
class Properties {
  super_tag?: string;
  name?: string;
  type?: string;
  description_title?: string;
  description?: string;
  region?: string;
  district?: string;
  location?: string;
  capacity?: string;
  bedroom_count?: string;
  bathroom_count?: string;
  status?: boolean;
  weight?: number;
  deposit?: string;
  free_cleaning_service_min_date?: string;
  url_alias?: string;
  categories?: Array<number>;
  features?: Array<number>;
  users?: Array<number>;
  property_images?: Array<string>;
  meta_title?: string;
  meta_description?: string;
}
class PropertyEditModal extends FormHandler {
  constructor(props: Props) {
    super(props);
    this.state = { entity: new Properties() };
  }

  propertyTypeOptions = [
    {
      value: "villa",
      label: "Villa",
    },
    {
      value: "hotel",
      label: "Otel",
    },
    {
      value: "pansion",
      label: "Pansiyon",
    },
  ];
  propertyRegionsOptions = [
    { value: "kas", label: "Kaş" },
    { value: "kalkan", label: "Kalkan" },
    { value: "fethiye", label: "Fethiye" },
    { value: "sapanca", label: "Sapanca" },
  ];

  propertyStatusOptions = [
    {
      value: "1",
      label: "Aktif",
    },
    {
      value: "0",
      label: "Pasif",
    },
  ];

  countOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];

  getApiUrl(): string {
    return "/properties/save/" + this.props.itemIdForUpdate;
  }
  getSingleUrl() {
    if (this.props.itemIdForUpdate === "add") {
      return null;
    } else {
      return "/properties/single/" + this.props.itemIdForUpdate;
    }
  }

  getPostData(): object {
    return {
      properties: this.state.entity,
    };
  }
  render(): ReactNode {
    var state = this.state as any;
    let defaultCategories = state.entity.categories || 0;
    let defaultFeatures = state.entity.features || 0;
    let defaultOwners = state.entity.users || 0;
    let defaultRegion = state.entity.region || 0;
    if (this.props.itemIdForUpdate === "add") {
      defaultCategories = 0;
      defaultFeatures = 0;
      defaultOwners = 0;
      defaultRegion = 0;
    } else {
      defaultCategories = state.entity.categories;
      defaultFeatures = state.entity.features;
      defaultOwners = state.entity.users;
      defaultRegion = state.entity.region;
    }
    console.log("state:", state.entity);
    return (
      <Modal
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered"
        size="lg"
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response?.data?.data?.entity_id);
              }
              this.props.onHide();
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.itemIdForUpdate === "add"
                ? "Mülk Ekle"
                : "Mülk Düzenle"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Field
                title="Öne Çıkarma Etiketi"
                className="form-control"
                type="text"
                name="super_tag"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.super_tag}
                required={true}
              />
              <Field
                title="Mülk İsmi"
                className="form-control"
                type="text"
                name="name"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.name}
                required={true}
              />
              <Select
                title="Mülk Tipi"
                name="type"
                defaultValue={state.entity.type}
                options={this.propertyTypeOptions}
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                required={true}
              />
              <Field
                title="Açıklama Başlığı"
                className="form-control"
                type="text"
                name="description_title"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.description_title}
                required={true}
              />
              <Textarea
                title="Açıklama"
                name="description"
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.description}
                required={true}
              />
              <FormGroup>
                <AutocompleteSelect
                  title="Bölge"
                  name="region"
                  onChange={(event: any) => {
                    this.handleInputChange(event);
                  }}
                  defaultValue={defaultRegion}
                  multiple={false}
                  autoCompleteTokenUrl="/regions/autocomplete"
                  required={true}
                />
              </FormGroup>
              <Field
                title="Mahalle"
                className="form-control"
                type="text"
                name="district"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.district}
                required={true}
              />
              <Field
                title="Konum"
                className="form-control"
                type="text"
                name="location"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.location}
                required={true}
              />
              <Select
                title="Kapasite"
                name="capacity"
                defaultValue={state.entity.capacity}
                options={this.countOptions}
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                required={true}
              />
              <Select
                title="Yatak Sayısı"
                name="bedroom_count"
                defaultValue={state.entity.bedroom_count}
                options={this.countOptions}
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                required={true}
              />
              <Select
                title="Banyo Sayısı"
                name="bathroom_count"
                defaultValue={state.entity.bathroom_count}
                options={this.countOptions}
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                required={true}
              />
              <Field
                title="Depozito"
                className="form-control"
                type="text"
                name="deposit"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.deposit}
                required={true}
              />
              <Field
                title="Ücretsiz Temizlik Hizmeti Min Gün"
                className="form-control"
                type="number"
                name="free_cleaning_service_min_date"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.free_cleaning_service_min_date}
                required={true}
              />
              <FormGroup>
                <AutocompleteSelect
                  title="Mülk Sahipleri"
                  name="users"
                  onChange={(event: any) => {
                    this.handleInputChange(event);
                  }}
                  defaultValue={defaultOwners}
                  multiple={true}
                  autoCompleteTokenUrl="/users/autocomplete"
                  required={true}
                />
              </FormGroup>
              <FormGroup>
                <AutocompleteSelect
                  title="Mülk Kategorileri"
                  name="categories"
                  onChange={(event: any) => {
                    this.handleInputChange(event);
                  }}
                  defaultValue={defaultCategories}
                  multiple={true}
                  autoCompleteTokenUrl="/categories/autocomplete"
                  required={true}
                />
              </FormGroup>
              <FormGroup>
                <AutocompleteSelect
                  title="Mülk Özellikleri"
                  name="features"
                  onChange={(event: any) => {
                    this.handleInputChange(event);
                  }}
                  defaultValue={defaultFeatures}
                  multiple={true}
                  autoCompleteTokenUrl="/features/autocomplete"
                  required={true}
                />
              </FormGroup>
              <FormGroup>
                <MultipleFileInputWidget
                  fileUploadKeyUrl="/properties/getPropertyImageUploadKey"
                  name="property_images"
                  onChange={(event) => {
                    this.handleInputChange(event);
                  }}
                  accept="image/*"
                  fieldName="image"
                  defaultFiles={state.entity.property_images}
                />
              </FormGroup>
              <Field
                title="Sıralaması"
                className="form-control"
                type="number"
                name="weight"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.weight}
                required={true}
              />
              <Field
                title="Url"
                className="form-control"
                type="text"
                name="url_alias"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.url_alias}
                required={false}
              />
              <Field
                title="Meta Başlığı"
                className="form-control"
                type="text"
                name="meta_title"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.meta_title}
                required={true}
              />
              <Textarea
                title="Meta Açıklaması"
                name="meta_description"
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.meta_description}
                required={true}
              />
              <Check
                title="Durum"
                name="status"
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                type="switch"
                checked={state.entity.status ? true : false}
                options={[""]}
                required={true}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            {this.props.itemIdForUpdate !== "add" ? (
              <button
                type="button"
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction("");
                    }
                  });
                }}
                className="btn btn-danger btn-sm"
              >
                <span className="fa fa-trash"></span>
                Sil
              </button>
            ) : (
              <></>
            )}
            <button type="submit" className="btn btn-success btn-sm">
              <span className="fa fa-floppy-save"></span>
              Kaydet
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export { PropertyEditModal };
