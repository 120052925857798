import Table from "../../../utils/ResultViewer/Table";
import "./badge.css";
class UsersTable extends Table {
  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (key == "status") {
      console.log(row[key]);
      let badgeClass = "badge-light-success";
      let status = "Aktif";
      if (row[key] === "active") {
        badgeClass = "badge-light-success";
      } else if (row[key] === "blocked") {
        badgeClass = "badge-light-warning";
        status = "Engellenmiş";
      } else if (row[key] === "banned") {
        badgeClass = "badge-light-danger";
        status = "Yasaklanmış";
      }
      return (
        <td key={colIndex}>
          <div className={"badge " + badgeClass} style={{ color: "black" }}>
            {status}
          </div>
        </td>
      );
    } else {
      return super.renderRow(row, key, colIndex);
    }
  }
}

export default UsersTable;
