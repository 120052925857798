import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import "@fullcalendar/react";
import { DateClickArg } from "@fullcalendar/interaction";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import PricingCalendar from "./PricingCalendar";
import { defaultEvents } from "./sample_data";
import PricingFilter from "./PricingFilter";
import PricesAddModal from "./PricesAddModal";

const PricingWrapper = () => {
  const [property, setProperty] = useState("");

  const [show, setShow] = useState<boolean>(false);
  const onCloseModal = () => {
    setShow(false);
    setEventData({});
    setDateInfo({});
  };
  const onOpenModal = () => setShow(true);
  const [isEditable, setIsEditable] = useState<boolean>(false);

  /*
   * event data
   */
  const [events, setEvents] = useState<EventInput[]>([...defaultEvents]);
  const [eventData, setEventData] = useState<EventInput>({});
  const [dateInfo, setDateInfo] = useState<{ start?: Date; end?: Date }>({});
  const [refresh, setRefresh] = useState(0);
  /*
    calendar events
    */
  // on date click
  const onDateClick = (arg: DateClickArg) => {
    setDateInfo({ start: arg.date });
    onOpenModal();
    setIsEditable(false);
  };

  // on event click
  const onEventClick = (arg: EventClickArg) => {
    const event = {
      id: String(arg.event.id),
      title: arg.event.title,
      className: arg.event.classNames[0],
    };
    setEventData(event);
    setIsEditable(true);
    onOpenModal();
  };

  // on drop
  const onDrop = (arg: any) => {
    const dropEventData = arg;
    const title = dropEventData.draggedEl.title;
    if (title == null) {
      // handle no title case
    } else {
      let newEvent = {
        id: String(events.length + 1),
        title: title,
        start: dropEventData ? dropEventData.dateStr : new Date(),
        className: dropEventData.draggedEl.attributes["data-class"]["value"],
      };
      const modifiedEvents = [...events];
      modifiedEvents.push(newEvent);
      setEvents(modifiedEvents);
    }
  };

  /**
   * on event drop
   */
  const onEventDrop = (arg: any) => {
    const modifiedEvents = [...events];
    const idx = modifiedEvents.findIndex((e) => e["id"] === arg.event.id);
    modifiedEvents[idx]["title"] = arg.event.title;
    modifiedEvents[idx]["className"] = arg.event.classNames;
    modifiedEvents[idx]["start"] = arg.event.start;
    modifiedEvents[idx]["end"] = arg.event.end;
    setEvents(modifiedEvents);
    setIsEditable(false);
  };

  const handleSelect = (start: Date, end: Date) => {
    // Tarih aralığı seçilmişse ve başlangıç tarihi ile bitiş tarihi aynı günse
    if (start.toDateString() === end.toDateString()) {
      // Başlangıç ve bitiş tarihini seçilen gün olarak ayarla
      setDateInfo({ start, end });
    } else {
      // Başlangıç tarihini seçilen gün olarak ayarla, bitiş tarihini bir gün sonraya al
      setDateInfo({ start, end: new Date(end.getTime() - 86400000) });
    }

    setIsEditable(false);
    onOpenModal();
  };
  const handleProperty = (property: string) => {
    setProperty(property);
  };
  const refreshCalendar = () => {
    setRefresh((prev) => prev + 1);
  };
  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col lg={12}>
                  <PricingFilter setProperty={handleProperty} />
                </Col>
              </Row>
              {property ? (
                <>
                  <Col lg={12}>
                    {/* fullcalendar control */}
                    <PricingCalendar
                      onDateClick={onDateClick}
                      onEventClick={onEventClick}
                      onDrop={onDrop}
                      onEventDrop={onEventDrop}
                      onSelect={handleSelect}
                      property={property}
                      refresh={refresh}
                      dateInfo={dateInfo}
                    />
                  </Col>
                </>
              ) : (
                <>Fiyatlandırma takvimini görmek için mülk seçiniz...</>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* add new event modal */}
      {show ? (
        <PricesAddModal
          show={show}
          dateInfo={dateInfo}
          defaultPropertyValue={property}
          onHide={() => {
            setShow(false);
          }}
          refreshFunction={refreshCalendar}
        />
      ) : /*
        <PricingEditEvent
          isOpen={show}
          onClose={onCloseModal}
          isEditable={isEditable}
          eventData={eventData}
          dateInfo={dateInfo}
          onUpdateEvent={onUpdateEvent}
          onRemoveEvent={onRemoveEvent}
          onAddEvent={onAddEvent}
          defaultPropertyValue={property}
        />*/
      null}
    </>
  );
};

export default PricingWrapper;
