import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import PageTitle from "../../utils/PageTitle";
import { PoolsList } from "./components/PoolsList";

const breadCrumbItems = [
  {
    label: "Pools",
    path: "/pools/",
    active: false,
  },
];

const PoolsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle
                breadCrumbItems={breadCrumbItems}
                title={"Properties"}
              />
              <PoolsList />
            </>
          }
        />
        <Route index element={<Navigate to="/properties/list" />} />
      </Route>
    </Routes>
  );
};

export default PoolsPage;
