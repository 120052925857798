import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import Sortable from "sortablejs";
import { updateImagesWeight } from "../../core/_requests";
import "./ImageGrid.css"; // CSS dosyasını ekliyoruz
import PageTitle from "../../../../utils/PageTitle";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PropertyImagesEditModal } from "./PropertyImagesEditModal";
import Swal from "sweetalert2";

interface Image {
  ID: number;
  image: string;
  url: string;
  weight: number;
}

const ImageGrid: React.FC = () => {
  const [images, setImages] = useState<Image[]>([]);
  const [order, setOrder] = useState<any>([]);
  const [previewImage, setPreviewImage] = useState<string | null>(null); // Önizleme görüntüsü için state ekledik
  const params = useParams();
  const navigate = useNavigate();
  const [propertyName, setPropertyName] = useState<string>(""); // Mülk adını tutacak state'i tanımladık
  const fetchPropertyImages = async () => {
    try {
      const response = await axios.get(
        "/property_images/getPropertyImages/" + params["property_id"]
      );
      setImages(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchPropertyName = async () => {
    try {
      const response = await axios.get(
        "/properties/getPropertyNameById/" + params["property_id"]
      );
      setPropertyName(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (params["property_id"]) {
      fetchPropertyImages();
      fetchPropertyName();
    }
  }, [params]);

  const fetchUpdateImagesWeight = async (data: any) => {
    try {
      await updateImagesWeight(data);
    } catch (error) {
      console.error("Error updating image weights:", error);
    }
  };

  useEffect(() => {
    const imageGrid = document.getElementById("imageGrid");

    if (imageGrid) {
      Sortable.create(imageGrid, {
        animation: 150,
        onEnd: (evt) => {
          const items = imageGrid.children;
          const or = Array.from(items).map((item, index) => ({
            id: Number(item.getAttribute("data-id")),
            weight: index,
          }));
          setOrder(or);
        },
      });
    }
  }, [images]);

  useEffect(() => {
    if (order.length > 0) {
      fetchUpdateImagesWeight(order);
    }
  }, [order]);

  const [previewImageId, setPreviewImageId] = useState<number | null>(null);
  const handleImageClick = (imageId: number, imageUrl: string) => {
    setPreviewImage(imageUrl);
    setPreviewImageId(imageId);
  };

  const handleClosePreview = () => {
    setPreviewImage(null); // Önizleme görüntüsünü kapat
  };
  const [showModal, setShowModal] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  // Modalı açan fonksiyon
  const handleShowModal = () => {
    setShowModal(true);
  };

  // Modalı kapatan fonksiyon
  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (refreshGrid) {
      setRefreshGrid(false); // State'i false yaparak yenileme işlemini bir kere gerçekleştir
      window.location.reload(); // Sayfayı yenile
    }
  }, [refreshGrid]);

  const handleDeleteImage = (id: any) => {
    axios.get("/property_images/save/" + id).then((response) => {
      let csrfData = response.data.data;
      Swal.fire({
        title: "Warning",
        html: "Are you sure you want to delete this image?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("property_images/save/" + id, {
              data: csrfData,
            })
            .then(() => {
              setImages(images.filter((image) => image.ID !== id)); // Silinen resmi listeden kaldır
              setPreviewImage(null); // Önizleme penceresini kapat
              Swal.fire("Deleted!", "The image has been deleted.", "success");
            })
            .catch((error) => {
              console.error("Error deleting image:", error);
              Swal.fire("Error", "Failed to delete image.", "error");
            });
        }
      });
    });
  };
  return (
    <Container fluid>
      <>
        <PageTitle
          breadCrumbItems={[
            { label: "Extra Pages", path: "/pages/starter" },
            { label: "Starter", path: "/pages/starter", active: true },
          ]}
          title={"Mülk Görselleri - " + propertyName}
        />
        <Row className="mb-3">
          <Col xs={6}>
            <div className="d-flex justify-content-start">
              <div className="me-2">
                <Link to="/properties/list" className="btn btn-primary">
                  <i className="fas fa-arrow-left"></i> Mülkler Listesi
                </Link>
              </div>
              <div>
                <Link
                  to={"/properties/edit/" + params["property_id"]}
                  className="btn btn-info"
                >
                  <i className="fas fa-arrow-left"></i> Mülk Detay Sayfası
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <div>
              {/* Yeni Görsel Ekle butonu */}
              <Button variant="success" onClick={handleShowModal}>
                <i className="fas fa-plus"></i> Yeni Görsel Ekle
              </Button>
            </div>
          </Col>
        </Row>
      </>
      <div id="imageGrid" className="image-grid">
        {images && images.length > 0 ? (
          images.map((image) => (
            <div
              key={image.ID}
              data-id={image.ID}
              className="image-item"
              onClick={() =>
                handleImageClick(
                  image.ID,
                  process.env.REACT_APP_API_BASE_URL +
                    "files/uploaded/" +
                    image.url
                )
              }
            >
              <img
                src={
                  process.env.REACT_APP_API_BASE_URL +
                  "files/uploaded/" +
                  image.url
                }
                alt="Image"
                className="image"
              />
            </div>
          ))
        ) : (
          <>
            <div className="no-images">
              <h3>Mülke ait görsel bulunamadı.</h3>
              <p>
                "Yeni Görsel Ekle" butonunu kullanarak görsel ekleyebilirsiniz.
              </p>
            </div>
          </>
        )}
      </div>
      {previewImage && (
        <div className="preview-overlay" onClick={handleClosePreview}>
          <div className="preview-container">
            <img src={previewImage} alt="Preview" className="preview-image" />
            <button onClick={handleClosePreview} className="close-button">
              X
            </button>
            <button
              onClick={() => handleDeleteImage(previewImageId)}
              className="btn btn-danger delete-button"
              style={{ marginLeft: "10px" }}
            >
              <i className="fas fa-trash-alt me-2"></i> Sil
            </button>
          </div>
        </div>
      )}
      <PropertyImagesEditModal
        show={showModal}
        onHide={handleCloseModal}
        itemIdForUpdate="add" // Ekleme modunda açılacak
        refreshFunction={() => {
          handleCloseModal();
          window.location.reload();
        }}
        propertyId={params["property_id"]}
      />
    </Container>
  );
};

export default ImageGrid;
