import React, { useEffect, useState } from "react";
import FullCalendar, { CalendarOptions } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { PricingList } from "../core/_model";
import { getPricingList } from "../core/_request";
import trLocale from "@fullcalendar/core/locales/tr";
import Legend from "./Legend";
import "./Calendar.css";

interface CalendarProps {
  onDateClick: (value: any) => void;
  onEventClick: (value: any) => void;
  onDrop: (value: any) => void;
  onEventDrop: (value: any) => void;
  onSelect: (start: Date, end: Date) => void;
  property: string;
  refresh: number;
  dateInfo?: { start?: Date; end?: Date };
}

const PricingCalendar = ({
  onDateClick,
  onEventClick,
  onDrop,
  onEventDrop,
  onSelect,
  property,
  refresh,
  dateInfo,
}: CalendarProps) => {
  const handleDateClick = (arg: any) => {
    onDateClick(arg);
  };
  const handleEventClick = (arg: any) => {
    onEventClick(arg);
  };
  const handleDrop = (arg: any) => {
    onDrop(arg);
  };
  const handleEventDrop = (arg: any) => {
    onEventDrop(arg);
  };
  const handleSelect = (arg: any) => {
    onSelect(arg.start, arg.end);
  };

  const [events, setEvent] = useState<PricingList>([]);
  const fetchPricingData = async (property: string, start: Date, end: Date) => {
    try {
      const data = await getPricingList(
        property,
        start.toISOString(),
        end.toISOString()
      );
      setEvent(data);
    } catch (error) {
      console.log("Error fetching pricing data:", error);
    }
  };
  const handleDatesSet = (arg: any) => {
    const startDate = arg.start;
    const endDate = arg.end;
    if (property) {
      fetchPricingData(property, startDate, endDate);
    }
  };

  useEffect(() => {
    if (!refresh || (!dateInfo?.start && !dateInfo?.end) || refresh == 0) {
      const today = new Date();
      const firstDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth()-3,
        1
      );
      const lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 3,
        0
      );
      if (property) {
        fetchPricingData(property, firstDayOfMonth, lastDayOfMonth);
      }
    } else {
      if (property) {
        const today = new Date();
        let firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 3,
          1
        );
        let lastDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 3,
          0
        );
        if (dateInfo?.start && dateInfo?.end) {
          firstDayOfMonth = new Date(
            dateInfo.start.getFullYear(),
            dateInfo.start.getMonth() - 3,
            1
          );
          lastDayOfMonth = new Date(
            dateInfo.end.getFullYear(),
            dateInfo.end.getMonth() + 3,
            0
          );
        } else if (dateInfo?.start && !dateInfo?.end) {
          firstDayOfMonth = new Date(
            dateInfo.start.getFullYear(),
            dateInfo.start.getMonth() - 3,
            1
          );
          lastDayOfMonth = new Date(
            dateInfo.start.getFullYear(),
            dateInfo.start.getMonth() + 3,
            0
          );
        }
        fetchPricingData(property, firstDayOfMonth, lastDayOfMonth);
      }
    }
  }, [property, refresh]);
  const renderEventContent = (eventInfo: any) => {
    const { event } = eventInfo;
    const { title, extendedProps } = event;
    const {
      date,
      price,
      discountPrice,
      partialPaidRate,
      minDate,
      status_start,
      status_end,
    } = extendedProps;

    let color1 = "";
    let color2 = "";
    switch (status_start) {
      case "available":
        color1 = "#80ff80";
        break;
      case "option_vilbox":
        color1 = "#FFFF00";
        break;
      case "option_other":
        color1 = "#FFA500";
        break;
      case "not_available_vilbox":
        color1 = "#d3a2dc";
        break;
      case "not_available_other":
        color1 = "#FF6666";
        break;
      default:
        color1 = "#D7D6D2";
        break;
    }
    switch (status_end) {
      case "available":
        color2 = "#80ff80";
        break;
      case "option_vilbox":
        color2 = "#FFFF00";
        break;
      case "option_other":
        color2 = "#FFA500";
        break;
      case "not_available_vilbox":
        color2 = "#d3a2dc";
        break;
      case "not_available_other":
        color2 = "#FF6666";
        break;
      default:
        color2 = "#D7D6D2";
        break;
    }
    let borderColor = "2px solid";
    let dateNumber = new Date(date).getDate();
    const gradientStyle = `linear-gradient(to right bottom, ${color1} 50%, ${color2} 50%)`;
    return (
      <div
      style={{
        border: `${borderColor}`,
        background: gradientStyle,
        height: "100%",
        alignContent: "end",
        ...(dateNumber === 1 && { borderColor: "red" }),
      }}
      >
      <p className="calendar-event">
        {price != null ? `Fiyat: ${price} TL` : ""}
        <br></br>
        {discountPrice != null ? `İndirimli Fiyat: ${discountPrice} TL` : ""}
        <br></br>
        <i>{partialPaidRate != null ? `Ön Ödeme: ${partialPaidRate} %` : ""}</i>
        <br></br>
        <i>{minDate != null ? `Min. Konaklama: ${minDate} Gün` : ""}</i>
      </p>
      </div>
    );
  };
  // Define calendar options
  const calendarOptions: CalendarOptions = {
    initialView: "multiMonth",
    views: {
      multiMonth: {
        type: "dayGrid",
        duration: { months: 3 },
        buttonText: "3 months",
      },
    },
    plugins: [dayGridPlugin, interactionPlugin, BootstrapTheme],
    handleWindowResize: true,
    themeSystem: "bootstrap",
    buttonText: {
      today: "Bugün",
      month: "Ay",
      prev: "Önceki Aylar",
      next: "Sonraki Aylar",
    },
    headerToolbar: {
      left: "prev today",
      center: "title",
      right: "next",
    },
    editable: true,
    selectable: true,
    droppable: true,
    locale: trLocale,
    events: events?.map((price: any) => ({
      id: price.daily_price_id,
      title: price.price + " TL",
      extendedProps: {
        date: price.date,
        price: price.price,
        discountPrice: price.discounted_price,
        partialPaidRate: price.partial_paid_rate,
        minDate: price.min_duration_date,
        status_start: price.status_start,
        status_end: price.status_end,
      },
      start: price.date,
      end: price.date,
      display: "background",
      classNames: ["pricing-event"],
      allDay: true,
    })),
    dateClick: handleDateClick,
    eventClick: handleEventClick,
    drop: handleDrop,
    eventDrop: handleEventDrop,
    select: handleSelect,
    datesSet: handleDatesSet,
    height: 1400,
    eventContent: renderEventContent,
    dayCellContent: function (arg) {
      const { date } = arg;
      const today = new Date();
      const isToday =
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
      if (date.getDate() === 1) {
        arg.dayNumberText = new Intl.DateTimeFormat("tr-TR", {
          month: "long",
        }).format(date);
      }
    },
  };

  return (
    <>
      {/* FullCalendar component */}
      <div id="calendar" style={{ marginBottom: "5px", position: "relative" }}>
        <FullCalendar {...calendarOptions} />
      </div>
      <Legend />
    </>
  );
};

export default PricingCalendar;
