/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useAuth } from "../core/AuthProvider";
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from "react-bootstrap";
import classNames from "classnames";
import FormInput from "../../form/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

/* bottom links */
const BottomLink = () => {
  const { t } = useTranslation();

  return (
    <Row className="mt-3">
      <Col className="text-center">
        <p>
          <Link to={"/auth/forget-password"} className="text-white-50 ms-1">
            {t("Forgot your password?")}
          </Link>
        </p>
        <p className="text-white-50">
          {t("Don't have an account?")}{" "}
          <Link to={"/auth/register"} className="text-white ms-1">
            <b>{t("Sign Up")}</b>
          </Link>
        </p>
      </Col>
    </Row>
  );
};

/* social links */
const SocialLinks = () => {
  const socialLinks = [
    {
      variant: "primary",
      icon: "facebook",
    },
    {
      variant: "danger",
      icon: "google",
    },
    {
      variant: "info",
      icon: "twitter",
    },
    {
      variant: "secondary",
      icon: "github",
    },
  ];
  return (
    <>
      <ul className="social-list list-inline mt-3 mb-0">
        {(socialLinks || []).map((item, index: number) => {
          return (
            <li key={index} className="list-inline-item">
              <Link
                to="#"
                className={classNames(
                  "social-list-item",
                  "border-" + item.variant,
                  "text-" + item.variant
                )}
              >
                <i className={classNames("mdi", "mdi-" + item.icon)}></i>
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const schemaResolver = yupResolver(
  yup.object().shape({
    username: yup
      .string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Username is required"),
    password: yup
      .string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
    //rememberMe: Yup.bool(),
  })
);

const initialValues = {
  username: "",
  password: "",
  rememberMe: true,
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const { user, login, loggedIn } = useAuth();
  const navigate = useNavigate();

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await login({
        username: username,
        password: e.target.password.value,
        rememberMe: rememberMe,
      });
      if (response) {
        setLoading(false);
        //console.log("User: ", { user });
        setSubmitted(true);
        //navigate("/dashboard-1");
      }
    } catch (e: any) {
      console.error({ e });
      setError(
        e?.response?.data?.messages[0]?.password || "An error occurred."
      );
      setLoading(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    /*if(user && loggedIn) {
            navigate("/dashboard-1");
        }*/
  }, [loggedIn]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <FormInput
          label={t("Username")}
          type="text"
          name="username"
          placeholder={t("Enter your Username")}
          containerClass={"mb-3"}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <FormInput
          label={t("Password")}
          type="password"
          name="password"
          placeholder={t("Enter your password")}
          containerClass={"mb-3"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></FormInput>
        <FormInput
          label={t("Remember Me")}
          type="checkbox"
          name="remember_me"
          placeholder={t("Remember Me")}
          containerClass={"mb-3"}
          checked={rememberMe}
          onChange={(e) => setRememberMe(e.target.checked)}
        ></FormInput>

        <div className="text-center d-grid">
          <Button variant="primary" type="submit" disabled={loading}>
            {t("Log In")}
          </Button>
        </div>
      </form>
      {loading && <p>{t("Loading")}...</p>}
      {error && (
        <p>
          {t("Error")}: {error}
        </p>
      )}
      {submitted && <p>{t("Login successful")}!</p>}
    </>
  );
}
