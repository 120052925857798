import { ChangeEvent, ReactNode } from "react";
import { FormGroup, Modal } from "react-bootstrap";
import FormHandler from "../../../../utils/Form/FormHandler";
import Field from "../../../../utils/Form/Widgets/Field";
import AutocompleteSelect from "../../../../utils/Form/Widgets/AutocompleteSelect";

type Props = {
  onHide: () => void;
  show: boolean;
  itemIdForUpdate?: string;
  refreshFunction?: Function;
  propertyId: string;
};
class PropertyPoolsEditModal extends FormHandler {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {
        property: props.propertyId,
        pool: "",
        width: "",
        length: "",
        depth: "",
      },
    };
  }

  getApiUrl(): string {
    return "/property_pools/save/" + this.props.itemIdForUpdate;
  }
  getSingleUrl() {
    if (this.props.itemIdForUpdate === "add") {
      return null;
    } else {
      return "/property_pools/single/" + this.props.itemIdForUpdate;
    }
  }

  getPostData(): object {
    return {
      property_pools: this.state.entity,
    };
  }
  render(): ReactNode {
    var state = this.state as any;
    let defaultPool = state.entity.pool || 0;
    if (this.props.itemIdForUpdate === "add") {
      defaultPool = 0;
    } else {
      defaultPool = state.entity.pool;
    }
    return (
      <Modal
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered"
        size="lg"
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response?.data?.data?.entity_id);
              }
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.itemIdForUpdate === "add"
                ? "Mülk Bağlantısı Ekle"
                : "Mülk Bağlantısı Düzenle"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Field
                name="property"
                onChange={() => {}}
                defaultValue={this.props.propertyId}
                readOnly={true}
                hidden={true}
              />

              <AutocompleteSelect
                title="Havuz Tipi"
                name="pool"
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={defaultPool}
                multiple={false}
                autoCompleteTokenUrl="/pools/autocomplete"
              />
              <Field
                title="Genişlik"
                className="form-control"
                type="text"
                name="width"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.width}
              />
              <Field
                title="Uzunluk"
                className="form-control"
                type="text"
                name="length"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.length}
              />
              <Field
                title="Derinlik"
                className="form-control"
                type="text"
                name="depth"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.depth}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            {this.props.itemIdForUpdate !== "add" ? (
              <button
                type="button"
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction("");
                    }
                  });
                }}
                className="btn btn-danger btn-sm"
              >
                <span className="fa fa-trash"></span>
                Sil
              </button>
            ) : (
              <></>
            )}
            <button type="submit" className="btn btn-success btn-sm">
              <span className="fa fa-floppy-save"></span>
              Kaydet
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export { PropertyPoolsEditModal };
