import { ReactNode } from "react";
import { FormGroup, Modal } from "react-bootstrap";
import FormHandler from "../../../utils/Form/FormHandler";
import Field from "../../../utils/Form/Widgets/Field";
import Select from "../../../utils/Form/Widgets/Select";
import FileInput from "../../../utils/Form/Widgets/FileInput";
import AutocompleteSelect from "../../../utils/Form/Widgets/AutocompleteSelect";

type Props = {
  onHide: () => void;
  show: boolean;
  itemIdForUpdate?: string;
  refreshFunction?: Function;
};

class User {
  name?: string;
  surname?: string;
  email?: string;
  phone?: string;
  password?: string;
  password_again?: string;
  status?: string;
  roles?: number[];
}

class UsersEditModal extends FormHandler {
  statusOptions = [
    {
      value: "active",
      label: "Active",
    },
    {
      value: "blocked",
      label: "Blocked",
    },
    {
      value: "banned",
      label: "Banned",
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = { entity: new User() };
  }

  getApiUrl(): string {
    return "/users/save/" + this.props.itemIdForUpdate;
  }
  getSingleUrl(): string | null {
    if (this.props.itemIdForUpdate === "add") {
      return null;
    } else {
      return "/users/single/" + this.props.itemIdForUpdate;
    }
  }

  getPostData(): object {
    return {
      users: this.state.entity,
    };
  }

  render(): ReactNode {
    var state = this.state as any;
    let title = "Add User";
    if (this.state.entity && this.props.itemIdForUpdate !== "add") {
      let entity = this.state.entity as any;
      title =
        entity.name +
        " " +
        (entity.surname ?? "") +
        " - " +
        (entity.username ?? "");
    }
    if (this.props.itemIdForUpdate === "add") {
      if (!state.entity.roles) {
        state.entity.roles = [];
      }
    }
    console.log("state:", state);
    return (
      <Modal
        id="kt_modal_create_user"
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered"
        size="lg"
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response?.data?.data?.entity_id);
              }
              this.props.onHide();
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Field
                title="Name"
                className="form-control"
                type="text"
                name="name"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.name}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title="Surname"
                className="form-control"
                type="text"
                name="surname"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.surname}
              />
            </FormGroup>
            <FormGroup>
              <FileInput
                fileUploadKeyUrl="/users/getProfilePhotoUploadKey"
                title="Profile Photo"
                name="profile_photo"
                onChange={(event) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.profile_photo}
                accept="image/*"
              />
            </FormGroup>
            <FormGroup>
              <Field
                title="Email"
                className="form-control"
                type="email"
                name="email"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.email}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title="Phone"
                className="form-control"
                type="tel"
                name="phone"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.phone}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title="Password"
                className="form-control"
                type="password"
                name="password"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title="Password again"
                className="form-control"
                type="password"
                name="password_again"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.password_again}
              />
            </FormGroup>
            <FormGroup>
              <Select
                title="Status"
                name="status"
                options={this.statusOptions}
                defaultValue={state.entity.status}
                required={true}
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                placeholder="Select status"
              />
            </FormGroup>
            <FormGroup>
              <AutocompleteSelect
                title="Roles"
                name="roles"
                onChange={(event: any) => {
                  this.setState({
                    entity: {
                      ...this.state.entity,
                      roles: event.target.value,
                    },
                  });
                }}
                defaultValue={state.entity.roles}
                multiple={true}
                autoCompleteTokenUrl="/users/rolesAutocomplete"
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            {this.props.itemIdForUpdate !== "add" ? (
              <button
                type="button"
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction("");
                    }
                  });
                }}
                className="btn btn-danger btn-sm"
              >
                <span className="fa fa-trash"></span>
                Sil
              </button>
            ) : (
              <></>
            )}
            <button type="submit" className="btn btn-success btn-sm">
              Kaydet
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export { UsersEditModal };
