import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import PageTitle from "../../utils/PageTitle";
import { WatchdogList } from "./WatchdogList";

const breadCrumbItems = [
  {
    label: "Son günlük olaylar",
    path: "/watchdog/",
    active: false,
  },
  {
    label: "",
    path: "/",
    active: false,
  },
];

const WatchdogPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle
                breadCrumbItems={[
                  {
                    label: "Son günlük olaylar",
                    path: "/watchdog/list",
                    active: true,
                  },
                ]}
                title={"Son Günlük Olaylar"}
              />
              <WatchdogList />
            </>
          }
        />
        <Route index element={<Navigate to="/watchdog/list" />} />
      </Route>
    </Routes>
  );
};

export default WatchdogPage;
